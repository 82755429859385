import Vue from 'vue'
import App from './App.vue'
import DigisystemVue from '@/digisystem/vue';

import { iconsSet as icons } from '@/assets/icons/icons.js'

import CIcon from '@/digisystem/icons-vue/src/CIcon'

import router from '@/router'
import store from './store.js'
import '@/config/axios.js'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import moment from 'moment'

Vue.component("CIcon", CIcon)

Vue.use(DigisystemVue);
Vue.config.productionTip = false
Vue.config.performance = true
Vue.prototype.$log = console.log.bind(console)
Vue.prototype.moment = moment

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
