<template>
  <router-view></router-view>
</template>

<script>
import { checkTenant } from "@/js/modules/auth.js";
export default {
  name: 'App',
  computed: {
    uri() {
      return this.$store.state.uri;
    },
  },
  data() {
    return {}
  },
  methods: {
    checkTenant
  },
  created() {
     let uri = {}
     uri.protocol = window.location.protocol
     uri.hostname = window.location.hostname 
     uri.port = window.location.port 
     uri.service = process.env.VUE_APP_SERVICE
    this.$store.commit("setUri", uri) 
    this.checkTenant(); 
  }
};
</script>
<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
