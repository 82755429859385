import Vue from 'vue'
import Router from 'vue-router'

// Containers
const Container = () => import('@/containers/Container')

//Fixo
const Home = () => import('@/modules/application/HomeContainer')
const Page404 = () => import('@/modules/application/Page404')
const Page500 = () => import('@/modules/application/Page500')
const Signin = () => import('@/modules/auth/Signin')


//Variaveis
const Component = () => import('@/modules/component/ComponentContainer')
const Domain = () => import('@/modules/domain/DomainContainer')
const Equipment = () => import('@/modules/equipment/EquipmentContainer')
const GeneralParameter = () => import('@/modules/general/GeneralParameterContainer')
const Macro = () => import('@/modules/macro/MacroContainer')
const Product = () => import('@/modules/product/ProductContainer')
const Seal = () => import('@/modules/seal/SealContainer')
const Software = () => import('@/modules/software/SoftwareContainer')
const Tenant = () => import('@/modules/tenant/TenantContainer')
const Trail = () => import('@/modules/trail/TrailContainer')

Vue.use(Router)

export default new Router({
  mode: 'hash', 
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
      {
          path: '/',
          name: 'auth',
          redirect: '/auth',
          component: {render(c) { return c('router-view') } },
          children: [{ path: '', name: 'Signin', component: Signin }]
      },
      {
          path: '/errors',
          name: 'errors',
          redirect: '/page-500',
          component: {render(c) { return c('router-view') } },
          children: [
              { path: 'page-404', name: 'Page404', component: Page404 },
              { path: 'page-500', name: 'Page500', component: Page500 },
          ]
      },
      {
          path: '/',
          name: 'authenticated',
          component: Container,
          children: [
              { path: 'home', name: 'Home', component: Home },
              { path: 'component', name: 'Compoent', component: Component },
              { path: 'domain', name: 'Domain', component: Domain },
              { path: 'equipment', name: 'Equipment', component: Equipment },
              { path: 'general-parameter', name: 'GeneralParameter', component: GeneralParameter },
              { path: 'product', name: 'Product', component: Product },
              { path: 'seal', name: 'Seal', component: Seal },
              { path: 'software', name: 'Software', component: Software },
              { path: 'tenant', name: 'Tenant', component: Tenant },
              { path: 'trail', name: 'Trail', component: Trail },
              { path: 'macro', name: 'Macro', component: Macro },
          ]
      },
     
  ]
}