import axios from 'axios'
import store from '@/store'
import router from '@/router'

//import CryptoJS from 'crypto-js'

const getURI = () => {
    let uri = ''
    let port = ''
    if(store.state.uri.port !== null && store.state.uri.port !== '') {
      port = `:${store.state.uri.port}`
    }
    uri = `${store.state.uri.protocol}//${store.state.uri.hostname}${port}/${store.state.uri.service}`
    return uri
}

const checkTenant = () => {
    let url =  `${getURI()}/api/v01/authentication/check-tenant`

    if (url !== null) {
        axios.post(url)
            .then(res => {
                router.push({ name: "Signin" });
                store.commit("setTenant", res.data[0])
                //store.commit("setTenant", Object.assign(res.data.tenant[0]))
            })
            .catch(error => {
                console.log('checkTenant - ERROR ', error)
                setErrorApplication(error)               
            })
    }
}

const signin = (payload) => {
    let url =  `${store.getters.restrictURI}/api/v01/authentication/authorize-user`

    let param = {... payload.userAuth}
    param.serial_number = store.state.tenant.serial_number
    param.tenant_id = store.state.tenant.tenant_id
    /*Implementacao futura para criptografar o payload 
    var hash = CryptoJS.SHA256(JSON.stringify(param),'teste').toString()
    console.log(hash)
    //const data = CryptoJS.AES.encrypt(paramt).toString();
    //jwtEncrypt.generateJWT()
    CryptoJS.SHA256(JSON.stringify(param), 'secret key 123').toString())
    */

    if (url !== null) {
        axios.post(url, param)
            .then(res => {
                router.push({ name: "Home" });
                store.commit("setUserAuth", res.data[0])
            })
            .catch(error => {
                setErrorApplication(error)               
            })
    }
}

//Metodos de controle interno
const setErrorApplication = (payload) => {
    let e = {}
    e.status = payload.response.status
    e.message = payload.response.data
    store.commit("setErrorApplication", e)

    if(e.status === 400) {
        router.push({ name: "Page404" });
    } else if(e.status === 500) {
        router.push({ name: "Page500" });
    }
}

export { checkTenant, signin }

