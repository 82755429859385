export const logo = [
    '556 134',
    `
    <title>coreui vue</title>

    <g id="#57ceecfe">
    <path fill="#57ceec" opacity="1.00" d=" M 57.23 11.62 C 54.01 10.99 54.68 5.56 57.97 5.64 C 59.97 5.20 60.58 7.90 61.72 8.99 C 60.54 10.25 59.33 12.44 57.23 11.62 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 72.41 6.52 C 75.23 3.15 79.89 9.22 76.06 11.25 C 73.27 13.48 69.37 8.67 72.41 6.52 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 47.66 14.68 C 48.24 14.38 49.39 13.80 49.97 13.51 C 50.66 13.88 52.04 14.63 52.73 15.00 C 52.98 16.77 53.04 19.22 50.84 19.73 C 48.89 20.58 47.56 18.58 46.45 17.36 C 46.75 16.69 47.36 15.35 47.66 14.68 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 79.53 16.94 C 79.17 14.90 81.81 14.27 83.05 13.26 C 84.31 14.55 86.47 15.91 85.48 18.04 C 84.46 21.00 79.38 20.10 79.53 16.94 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 40.45 22.34 C 42.72 21.30 45.55 23.38 44.63 25.89 C 44.20 28.24 40.49 28.69 39.28 26.78 C 37.91 25.53 38.82 22.87 40.45 22.34 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 63.30 32.89 C 63.19 29.73 68.28 29.24 69.11 32.12 C 70.40 34.47 67.53 35.76 65.97 36.75 C 64.86 35.65 62.85 34.80 63.30 32.89 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 81.49 30.37 C 84.87 28.88 87.44 34.30 84.02 35.82 C 80.49 38.26 77.44 31.62 81.49 30.37 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 96.42 30.43 C 98.12 28.66 101.39 29.31 102.36 31.54 C 103.59 33.84 101.76 37.12 99.04 36.83 C 95.61 37.38 93.86 32.49 96.42 30.43 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 24.49 38.49 C 27.35 37.38 29.95 41.57 27.47 43.52 C 26.23 45.44 23.94 43.73 22.39 43.26 C 22.48 41.51 22.21 38.91 24.49 38.49 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 40.56 38.56 C 42.65 37.62 45.27 39.59 44.70 41.89 C 44.55 44.22 41.03 45.45 39.59 43.50 C 37.78 42.10 38.68 39.40 40.56 38.56 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 73.54 38.48 C 76.36 37.40 78.97 41.51 76.54 43.47 C 75.62 44.85 73.50 44.76 72.44 43.63 C 70.54 42.27 71.23 38.93 73.54 38.48 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 58.08 44.40 C 54.28 44.81 54.15 38.11 57.93 38.39 C 59.73 37.91 60.50 40.11 61.69 40.98 C 60.61 42.13 60.10 44.83 58.08 44.40 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 104.53 39.61 C 105.76 37.74 109.04 37.93 109.86 40.09 C 111.50 42.70 107.43 46.03 105.26 43.73 C 103.89 42.90 103.98 40.91 104.53 39.61 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 15.26 47.24 C 17.13 45.12 20.76 47.40 20.23 49.95 C 20.18 52.18 16.72 53.60 15.19 51.80 C 13.77 50.81 13.88 48.20 15.26 47.24 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 113.29 47.30 C 114.86 45.43 118.18 46.82 118.30 49.07 C 119.03 51.72 115.01 53.89 113.28 51.72 C 111.92 50.71 111.90 48.25 113.29 47.30 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 7.42 54.38 C 9.87 53.46 13.06 55.18 12.66 58.04 C 12.76 61.44 7.71 62.90 5.89 60.04 C 4.07 58.29 5.25 55.15 7.42 54.38 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 40.18 55.14 C 43.36 52.72 46.77 58.44 43.36 60.34 C 40.08 62.45 36.52 56.93 40.18 55.14 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 89.16 55.14 C 92.36 52.70 95.88 58.45 92.34 60.36 C 89.08 62.33 85.64 57.02 89.16 55.14 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 121.46 55.49 C 122.83 53.55 126.16 54.90 126.47 57.00 C 127.29 59.50 123.98 61.72 121.90 60.31 C 120.24 59.29 119.82 56.73 121.46 55.49 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 32.10 63.26 C 33.95 61.79 36.97 63.86 36.57 66.05 C 36.78 67.83 34.76 69.17 33.13 68.81 C 30.34 68.76 29.39 64.30 32.10 63.26 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 63.41 66.79 C 62.56 64.74 64.61 63.47 65.90 62.32 C 67.14 63.09 68.42 63.83 69.42 64.92 C 69.15 66.20 68.79 67.45 68.36 68.68 C 66.59 68.72 63.79 69.20 63.41 66.79 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 95.26 65.90 C 96.31 64.53 97.53 63.31 98.93 62.29 C 101.10 63.26 103.19 65.89 101.08 68.04 C 98.95 70.16 96.33 67.94 95.26 65.90 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 39.99 71.11 C 42.04 71.03 44.94 71.27 44.77 74.03 C 44.74 77.91 39.29 77.59 38.30 74.33 C 38.72 73.52 39.56 71.91 39.99 71.11 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 72.58 71.47 C 74.58 70.49 77.65 71.40 77.42 74.01 C 77.83 76.26 74.99 77.49 73.18 76.74 C 70.90 76.13 70.79 72.73 72.58 71.47 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 88.68 71.20 C 90.45 71.29 93.39 70.71 93.67 73.21 C 94.88 76.39 90.01 78.35 88.28 75.75 C 86.83 74.55 88.59 72.65 88.68 71.20 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 121.57 71.51 C 123.48 70.47 126.78 71.40 126.54 73.98 C 126.92 76.81 122.55 78.01 121.06 75.87 C 119.89 74.64 120.39 72.56 121.57 71.51 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 113.19 79.04 C 115.35 77.35 119.21 79.24 118.89 82.08 C 119.59 85.69 114.16 87.20 112.37 84.48 C 110.84 82.92 111.41 80.16 113.19 79.04 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 16.06 85.12 C 13.02 84.38 13.71 79.05 16.95 79.32 C 18.75 78.77 19.46 81.08 20.66 81.92 C 19.79 83.49 18.25 86.47 16.06 85.12 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 71.77 88.84 C 73.03 86.28 77.37 87.18 77.40 90.03 C 78.07 92.25 75.36 92.98 73.99 93.91 C 72.53 92.71 70.35 90.90 71.77 88.84 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 89.47 87.61 C 91.48 86.43 94.30 88.47 93.75 90.75 C 93.56 93.55 89.31 94.55 88.14 91.85 C 86.92 90.34 88.02 88.41 89.47 87.61 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 23.23 88.26 C 24.51 86.76 27.26 87.30 28.00 89.10 C 29.43 90.95 27.09 93.94 25.05 93.52 C 22.49 93.56 21.23 89.77 23.23 88.26 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 58.08 93.41 C 54.78 93.63 54.04 88.60 56.89 87.43 C 59.10 86.60 60.38 88.66 61.71 89.98 C 60.61 91.11 60.06 93.80 58.08 93.41 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 104.52 88.59 C 105.66 87.05 108.18 86.75 109.40 88.36 C 111.42 90.05 109.47 93.73 107.03 93.43 C 104.64 93.43 103.44 90.56 104.52 88.59 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 64.40 95.27 C 66.49 93.70 69.87 95.47 69.90 98.05 C 70.59 101.06 66.69 103.64 64.27 101.69 C 61.84 100.41 61.89 96.42 64.40 95.27 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 31.48 96.39 C 33.86 93.54 38.59 98.14 35.68 100.62 C 33.13 103.89 28.02 98.95 31.48 96.39 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 97.21 95.99 C 100.24 93.56 103.80 98.66 100.87 100.87 C 97.80 103.60 93.59 98.24 97.21 95.99 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 89.20 104.15 C 91.09 102.44 94.22 104.66 93.75 107.01 C 93.93 109.14 90.69 110.71 89.17 109.19 C 87.12 108.28 87.20 105.02 89.20 104.15 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 46.62 115.08 C 46.38 111.84 51.44 110.96 52.56 113.89 C 53.49 116.10 51.37 117.37 50.03 118.70 C 48.93 117.59 46.31 117.04 46.62 115.08 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 65.89 117.85 C 62.21 118.11 62.31 111.68 66.01 112.09 C 67.97 111.72 68.71 113.82 69.79 114.99 C 68.68 116.17 67.89 118.28 65.89 117.85 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 79.60 112.80 C 81.25 112.44 83.10 111.07 84.58 112.56 C 86.96 114.20 85.13 118.49 82.23 117.79 C 79.66 117.67 79.42 114.78 79.60 112.80 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 70.57 123.09 C 70.28 120.85 72.75 119.06 74.83 119.33 C 76.75 119.59 77.53 121.60 78.60 122.94 C 77.64 124.34 77.06 126.59 75.06 126.77 C 72.85 127.35 70.31 125.40 70.57 123.09 Z" />
    <path fill="#57ceec" opacity="1.00" d=" M 65.05 134.24 C 62.16 133.13 62.73 128.15 65.97 128.28 C 67.84 127.97 68.68 130.00 69.80 131.05 C 68.59 132.58 67.50 135.32 65.05 134.24 Z" />
    </g>
    <g id="#ffffffff">
    <path fill="#ffffff" opacity="1.00" d=" M 63.43 13.41 C 65.74 11.14 70.27 12.77 70.64 15.97 C 71.61 19.41 67.22 22.91 64.17 20.88 C 61.34 19.52 60.77 15.26 63.43 13.41 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 53.52 23.53 C 54.77 22.33 55.88 20.32 57.91 20.61 C 62.19 20.03 64.41 26.56 60.61 28.70 C 57.17 31.44 52.64 27.39 53.52 23.53 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 47.40 29.48 C 50.70 26.60 56.26 31.29 54.03 35.04 C 52.94 37.55 49.41 38.62 47.23 36.84 C 44.70 35.17 44.81 31.04 47.40 29.48 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 87.57 37.84 C 88.76 37.41 89.96 36.98 91.15 36.54 C 93.32 37.47 95.80 39.21 95.28 41.94 C 94.99 45.59 89.85 47.15 87.49 44.46 C 85.52 42.65 86.35 39.82 87.57 37.84 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 45.44 49.02 C 45.56 44.47 52.93 43.65 54.16 47.99 C 55.61 51.04 52.57 53.53 50.01 54.47 C 47.66 53.63 44.82 51.99 45.44 49.02 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 78.33 50.90 C 77.27 48.05 79.92 44.57 82.99 45.15 C 85.95 45.15 88.29 48.98 86.60 51.54 C 85.92 52.98 84.29 53.57 83.07 54.46 C 81.23 53.75 78.89 53.08 78.33 50.90 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 94.47 49.04 C 94.65 44.44 101.88 43.67 103.23 47.98 C 104.54 51.12 101.65 53.59 98.97 54.47 C 96.68 53.60 93.86 51.97 94.47 49.04 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 232.61 53.96 C 232.29 49.04 237.54 45.95 241.96 46.45 C 246.49 46.80 251.66 45.33 255.57 48.32 C 258.65 50.47 258.37 54.67 258.30 57.98 C 256.62 59.53 254.58 59.29 252.60 58.50 C 252.20 56.22 253.14 53.32 251.03 51.71 C 247.37 51.20 243.57 51.33 239.90 51.64 C 237.60 53.41 238.59 56.54 238.41 59.01 C 238.21 60.32 239.00 61.38 239.96 62.16 C 245.36 63.12 251.78 60.61 256.29 64.73 C 259.26 68.05 258.19 72.94 258.23 77.01 C 258.16 80.73 254.78 83.84 251.11 83.79 C 247.37 83.82 243.64 83.82 239.90 83.79 C 236.67 83.90 233.49 81.47 232.76 78.31 C 232.49 76.16 232.60 73.98 232.67 71.82 C 233.73 71.42 234.80 71.02 235.86 70.62 C 236.68 71.05 237.50 71.49 238.33 71.92 C 238.50 74.49 237.78 77.61 240.32 79.25 C 244.17 78.47 249.86 81.03 252.32 77.09 C 252.13 74.00 253.50 70.11 251.19 67.63 C 245.91 65.98 239.44 69.05 234.87 65.08 C 231.67 62.33 232.72 57.68 232.61 53.96 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 265.08 46.89 C 266.75 46.49 269.17 44.42 270.15 46.89 C 273.26 52.01 275.74 57.50 278.73 62.70 C 282.40 57.16 284.46 50.65 288.56 45.40 C 289.90 45.93 291.24 46.47 292.57 47.04 C 292.35 48.48 292.02 49.91 291.32 51.20 C 288.18 57.43 284.66 63.48 281.67 69.79 C 281.45 74.41 281.79 79.03 281.48 83.64 C 279.54 84.35 276.38 85.21 275.90 82.36 C 275.57 78.00 276.16 73.58 275.66 69.24 C 272.50 62.78 268.90 56.54 265.68 50.11 C 265.19 49.11 265.23 47.97 265.08 46.89 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 301.49 48.64 C 305.25 45.34 310.48 46.75 315.01 46.45 C 318.54 46.11 322.58 47.45 324.26 50.80 C 325.11 53.23 324.63 55.88 324.68 58.41 C 322.97 59.21 321.18 59.39 319.40 58.67 C 319.17 56.39 319.45 53.89 318.08 51.89 C 314.54 50.99 310.75 51.49 307.13 51.56 C 305.43 51.50 305.30 53.50 304.91 54.71 C 304.88 57.19 304.15 60.78 306.82 62.21 C 312.01 63.09 318.13 60.70 322.54 64.45 C 325.98 67.26 324.39 72.19 324.75 76.02 C 325.36 79.90 322.01 83.58 318.20 83.76 C 314.45 83.87 310.69 83.78 306.94 83.80 C 303.95 84.05 301.00 82.16 299.74 79.49 C 299.01 77.03 299.39 74.41 299.36 71.89 C 300.24 71.46 301.12 71.03 302.00 70.60 C 302.67 70.87 304.01 71.40 304.68 71.66 C 304.90 73.94 305.16 76.23 305.57 78.49 C 309.76 79.58 314.23 79.53 318.44 78.53 C 319.22 75.09 320.08 71.05 318.14 67.85 C 314.99 66.42 311.32 67.48 307.97 67.18 C 303.87 67.61 299.02 64.60 299.33 60.08 C 299.59 56.28 298.17 51.48 301.49 48.64 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 331.57 47.43 C 332.15 46.08 333.76 46.60 334.90 46.45 C 341.56 46.51 348.23 46.40 354.89 46.50 C 356.34 46.20 356.68 47.84 357.42 48.72 C 357.01 49.62 356.61 50.52 356.21 51.42 C 353.19 51.45 350.17 51.44 347.15 51.43 C 347.20 61.98 347.13 72.53 347.19 83.07 C 345.55 84.71 343.36 84.49 341.40 83.60 C 341.14 72.88 341.33 62.15 341.31 51.43 C 338.08 51.44 334.85 51.50 331.63 51.32 C 331.61 50.04 330.86 48.62 331.57 47.43 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 363.33 50.00 C 363.06 48.27 364.24 46.26 366.16 46.50 C 372.24 46.50 378.34 46.28 384.41 46.67 C 384.50 48.21 384.53 49.75 384.48 51.29 C 379.35 51.59 374.20 51.39 369.07 51.44 C 369.14 60.70 369.04 69.96 369.12 79.22 C 374.44 79.32 379.78 78.97 385.09 79.41 C 385.58 80.75 385.44 82.20 385.45 83.61 C 378.67 83.93 371.86 83.85 365.07 83.70 C 363.68 83.82 363.37 82.02 363.35 80.99 C 363.30 70.66 363.34 60.33 363.33 50.00 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 416.10 51.01 C 416.48 48.58 418.44 46.28 421.05 46.33 C 423.29 46.15 427.07 46.92 426.59 49.90 C 426.57 61.10 426.66 72.30 426.53 83.50 C 425.06 83.97 423.55 84.67 421.99 84.16 C 420.23 82.60 420.79 80.13 420.70 78.06 C 420.78 71.35 420.59 64.64 420.85 57.93 C 420.55 57.85 419.96 57.70 419.67 57.62 C 417.09 66.02 414.72 74.50 412.00 82.84 C 411.20 85.33 407.28 84.48 406.97 82.11 C 404.09 73.63 401.79 64.96 398.86 56.49 C 398.35 57.96 398.13 59.46 398.20 61.00 C 398.26 68.12 398.17 75.24 398.26 82.36 C 397.89 82.87 397.15 83.89 396.78 84.40 C 395.35 84.25 393.95 83.95 392.58 83.58 C 392.30 72.70 392.55 61.81 392.44 50.93 C 391.19 45.29 400.89 44.74 402.39 49.31 C 404.98 57.52 407.07 65.90 409.88 74.05 C 411.65 66.29 414.09 58.71 416.10 51.01 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 105.38 53.54 C 107.95 52.51 111.37 54.09 111.58 57.01 C 112.36 60.29 108.34 63.39 105.38 61.80 C 101.70 60.61 101.79 54.82 105.38 53.54 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 48.46 61.53 C 52.35 60.02 56.52 65.43 53.47 68.59 C 51.80 71.03 47.99 71.01 46.34 68.56 C 44.30 66.28 45.74 62.51 48.46 61.53 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 374.46 61.49 C 376.38 59.41 380.05 60.63 380.90 63.19 C 382.39 66.36 377.96 70.10 375.10 67.99 C 372.78 66.77 372.42 63.11 374.46 61.49 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 55.47 70.36 C 58.18 68.11 62.62 70.53 62.59 73.93 C 62.81 77.37 58.18 80.13 55.46 77.68 C 52.91 76.03 53.03 72.04 55.47 70.36 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 104.34 70.50 C 106.97 68.01 111.88 70.49 111.62 74.06 C 111.82 77.48 107.05 80.16 104.43 77.59 C 102.00 76.02 102.02 72.16 104.34 70.50 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 64.20 78.21 C 67.19 76.27 71.50 79.54 70.67 82.93 C 70.36 86.61 65.03 88.00 62.82 85.09 C 60.76 83.08 61.66 79.35 64.20 78.21 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 80.44 78.29 C 83.42 76.30 86.59 79.09 87.48 81.94 C 86.74 84.32 85.17 87.14 82.22 86.62 C 77.93 86.69 76.60 80.01 80.44 78.29 Z" />
    <path fill="#ffffff" opacity="1.00" d=" M 97.09 78.09 C 100.47 76.18 104.97 80.46 103.12 83.92 C 101.96 86.78 97.75 87.63 95.70 85.27 C 93.45 83.24 94.36 79.23 97.09 78.09 Z" />
    </g>
    <g id="#fe6c2dff">
    <path fill="#fe6c2d" opacity="1.00" d=" M 31.45 45.54 C 34.73 43.65 39.48 47.18 37.86 50.82 C 37.13 54.02 32.56 54.93 30.42 52.63 C 28.36 50.71 28.90 46.80 31.45 45.54 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 62.30 47.38 C 64.07 43.72 70.45 44.81 70.63 48.97 C 71.32 52.05 68.30 53.67 65.94 54.61 C 63.32 53.41 60.08 50.42 62.30 47.38 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 134.38 46.58 C 139.26 46.30 144.16 46.52 149.04 46.47 C 152.91 46.25 157.50 48.81 157.60 53.07 C 157.72 61.08 157.68 69.10 157.63 77.11 C 157.72 80.65 154.41 83.64 150.99 83.75 C 147.51 83.90 144.02 83.81 140.54 83.68 C 140.51 82.25 140.50 80.82 140.55 79.39 C 144.20 79.17 147.90 79.40 151.49 78.52 C 152.31 71.42 151.68 64.25 151.90 57.12 C 151.72 55.22 152.42 52.47 150.15 51.57 C 146.17 51.25 142.17 51.52 138.18 51.42 C 137.64 61.63 137.81 71.86 137.71 82.08 C 137.90 85.04 134.22 84.31 132.40 83.73 C 132.07 72.47 132.32 61.19 132.25 49.93 C 132.04 48.41 132.63 46.71 134.38 46.58 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 167.75 46.65 C 169.27 46.23 170.88 46.29 172.41 46.64 C 172.93 48.38 172.80 50.23 172.84 52.03 C 172.76 62.53 172.89 73.03 172.76 83.53 C 171.02 84.07 167.99 85.18 167.30 82.76 C 166.95 72.86 167.29 62.93 167.14 53.02 C 167.23 50.90 166.84 48.64 167.75 46.65 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 182.69 52.91 C 182.92 48.40 187.98 46.08 192.00 46.45 C 196.20 46.66 201.01 45.54 204.60 48.39 C 207.60 50.54 207.36 54.74 207.00 58.00 C 206.54 60.29 203.49 59.88 201.92 59.05 C 200.63 56.73 202.48 53.07 199.64 51.62 C 196.57 51.28 193.43 51.30 190.35 51.58 C 188.01 52.24 188.43 55.06 188.38 56.94 C 188.54 64.09 188.13 71.25 188.65 78.39 C 192.12 79.34 195.75 79.44 199.31 79.12 C 200.46 78.81 201.68 78.24 201.67 76.87 C 202.14 73.25 201.54 69.58 202.00 65.96 C 202.93 63.94 206.13 63.94 207.43 65.59 C 207.82 69.42 207.62 73.30 207.56 77.15 C 207.67 80.66 204.36 83.64 200.98 83.74 C 197.32 83.88 193.65 83.82 189.99 83.78 C 186.31 84.00 182.56 80.86 182.67 77.06 C 182.63 69.01 182.59 60.95 182.69 52.91 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 217.54 46.47 C 219.18 46.43 220.84 46.34 222.47 46.64 C 223.33 57.38 222.56 68.22 222.85 79.00 C 222.62 80.86 223.61 83.76 221.08 84.35 C 219.82 84.20 218.58 83.94 217.36 83.57 C 217.33 71.21 216.98 58.82 217.54 46.47 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 23.48 53.59 C 26.10 52.42 29.57 53.99 29.84 56.98 C 30.59 60.40 26.27 63.62 23.31 61.56 C 20.17 60.11 20.34 54.94 23.48 53.59 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 72.50 53.67 C 75.38 52.21 79.28 54.67 78.80 57.97 C 79.00 61.77 73.60 63.71 71.17 60.88 C 68.79 58.83 69.78 54.87 72.50 53.67 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 80.79 61.75 C 83.98 60.15 86.62 63.24 87.56 65.99 C 86.43 68.13 84.89 70.95 81.99 70.42 C 77.57 70.09 76.69 63.28 80.79 61.75 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 14.45 62.36 C 17.10 59.80 21.96 62.47 21.64 65.98 C 21.80 69.49 17.08 71.94 14.42 69.48 C 12.02 67.81 11.99 63.99 14.45 62.36 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 193.39 63.33 C 195.86 60.93 200.58 63.58 199.77 66.96 C 199.65 69.91 195.51 71.67 193.38 69.54 C 191.37 68.06 191.24 64.76 193.39 63.33 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 23.18 70.13 C 25.87 68.37 30.01 70.76 29.76 73.99 C 30.16 77.63 25.12 80.21 22.46 77.57 C 19.97 75.67 20.44 71.54 23.18 70.13 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 31.40 78.32 C 34.04 76.63 38.09 78.85 37.91 82.03 C 38.35 85.04 35.00 87.49 32.21 86.52 C 28.49 85.75 27.92 79.80 31.40 78.32 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 37.25 90.98 C 36.63 87.91 39.62 86.23 42.04 85.39 C 44.58 86.67 47.73 89.55 45.60 92.58 C 43.81 96.31 37.44 95.18 37.25 90.98 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 47.61 94.64 C 50.91 92.69 55.56 96.17 54.22 99.86 C 53.31 103.46 47.78 104.07 45.98 100.93 C 44.71 98.87 45.41 95.80 47.61 94.64 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 80.42 94.57 C 83.80 92.59 88.62 96.27 86.89 99.99 C 85.93 103.02 81.60 103.90 79.48 101.62 C 77.43 99.69 77.90 95.88 80.42 94.57 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 56.44 102.53 C 60.15 100.84 64.14 105.61 61.93 108.98 C 60.48 111.69 56.44 112.04 54.55 109.61 C 52.47 107.44 53.68 103.48 56.44 102.53 Z" />
    <path fill="#fe6c2d" opacity="1.00" d=" M 72.46 102.63 C 74.99 101.31 78.64 103.02 78.78 105.98 C 79.64 109.38 75.31 112.72 72.35 110.68 C 69.15 109.32 69.30 103.96 72.46 102.63 Z" />
    </g>
  `,
  ]