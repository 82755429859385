import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

const state = {
  modal: {},
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  showFilter: true,
  showSplit: false,
  uri: null,
  tenant: {},
  userAuth:{},
  errorApplication: {},

  loading: false,
  isOwner: true,

  switchesIcon: {
    labelOn:'\u2713',
    labelOff:'\u2715'
  },

  message: {}
}

const mutations = {
  setMessageModal(state,payload) {
    state.modal = payload
  },
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  toggleFilter (state, payload) {
    if (payload === undefined) {
      state.showFilter = !state.showFilter
    } else {
      state.showFilter = payload
    } 
      
  },
  splitDisplay (state) {
    state.showFilter = !state.showSplit
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  setUri(state, payload) {
    state.uri = payload
  },
  setTenant(state, payload) {
    state.tenant = payload
    axios.defaults.timeout = 0
    if(payload) {
      axios.defaults.headers.common['Authorization'] = payload.token
      axios.defaults.headers.common['sn'] = payload.serial_number
      state.uri = state.uri.replace(process.env.VUE_APP_SERVICE, `services/${process.env.VUE_APP_SERVICE}`)
    } else {
        delete axios.defaults.headers.common['Authorization']
        delete axios.defaults.headers.common['sn']
    }
  },
  setUserAuth(state, payload) {
    delete axios.defaults.headers.common['Authorization']    
    state.userAuth = payload
    state.isOwner = !state.userAuth.owner

    axios.defaults.timeout = 0
    if(payload) {
      axios.defaults.headers.common['Authorization'] = payload.token
    } else {
        delete axios.defaults.headers.common['Authorization']
    }
  },

  setErrorApplication(state, payload) {
    state.errorApplication = payload
  },
 
  toggleLoading (state, payload) {
    if (payload === undefined) {
      state.loading = !state.loading
    } else {
      state.loading = payload
    } 
  },

  setMessage (state, payload) {
    state.message = payload      
  },
}

const getters = {
  restrictURI (state) {
    let uri = ''
    let port = ''
    if(state.uri.port !== null && state.uri.port !== '') {
      port = `:${state.uri.port}`
    }
    if(state.userAuth.token !== null && process.env.VUE_APP_PRODUCTION === 'true') {
      uri = `${state.uri.protocol}//${state.uri.hostname}${port}/services/${state.uri.service}`
    } else {
      uri = `${state.uri.protocol}//${state.uri.hostname}${port}/${state.uri.service}`
    }
    return uri
  },
}

export default new Vuex.Store({
  state,
  mutations,
  getters
})